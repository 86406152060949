'use strict';

/**
 * @ngdoc function
 * @name informaApp.directive:infSelect
 * @description
 * # infSelect
 * Directive of the informaApp
 */
angular.module('informaApp')
  .directive('infChart', function () {

    return{
      restrict: 'E',
      scope:{
        data: "=",
        options: "="
      },
      link: function(scope, el, attrs, controllers){
        scope.$watchCollection('[data, options]', function(newVal, oldVal){
          if(!newVal[0]){
            return;
          }
        })
      },
      templateUrl: 'directives/inf-chart/template.ptl.html'
    }

  });
